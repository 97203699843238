<template>
  <main>
    <div class="position-relative">
      <!-- Hero -->
      <div class="bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-trust-hero.svg') + ')'}">
        <div class="container space-2 space-top-3 text-center">
          <div class="w-md-85 w-lg-65 text-center mx-md-auto mb-4">
            <h2 class="divider divider-text mb-3">Aug 2023 Release</h2>
            <h1 class="mb-3">What's New on FabriXAPI</h1>
            <p>Create your own branded API Portal with enhanced developer and API management controls</p>
          </div>
        </div>
      </div>
      <!-- End Hero -->

      <div class="container">
        <div class="row align-items-center space-2">
          <div class="col-12 col-md-6 mb-4 mb-md-0">
            <h3 class="mb-3">Your Portal Your Brand</h3>
            <p>Showcase your unique identity by prominently featuring your own logo on the API Portal, login page and admin portals. This allows you to create a distinct and memorable impression on your API users, making your API Portal an extension of your brand.</p>
            <a href="https://provider-portal.fabrixapi.com/" class="font-weight-bold" target="_blank">Create Portal for Free <i class="fas fa-arrow-right ml-1" /></a>

          </div>

          <div class="col-12 col-md-6">
            <div class="w-80 w-md-100 w-lg-75 mx-auto">
              <img class="img-fluid" src="@/assets/svg/illustrations/management/management-feature-1.svg" alt="Your Portal Your Brand">
            </div>
          </div>
        </div>

        <div class="row align-items-center space-2">
          <div class="col-12 col-md-6 mb-4 mb-md-0 order-md-2">
            <h3 class="mb-3">The Developer is in the Details</h3>
            <p>Obtain detailed information about registered and logged-in API users on your API Portal to gain valuable insights into user activity and engagement. Furthermore, you have the ability to invite API users to subscribe to API plans that are specifically tailored to their needs.</p>
            <a href="https://guide.fabrixapi.com/en-us/developer-list/" class="font-weight-bold" target="_blank">Learn More <i class="fas fa-arrow-right ml-1" /></a>
          </div>
          <div class="col-12 col-md-6 order-md-1">
            <div class="w-80 w-md-100 w-lg-75 mx-auto">
              <img class="img-fluid" src="@/assets/img/products/whats-new-aug2023/developers.jpg" alt="The Developer is in the Details">
            </div>
          </div>
        </div>

        <div class="row align-items-center space-2">
          <div class="col-12 col-md-6 mb-4 mb-md-0">
            <h3 class="mb-3">Customizable API Approval Settings</h3>
            <p>You now possess the capability to seamlessly configure whether API users should seek your approval before initiating their API subscriptions, providing you with enhanced control over the subscription process.</p>
            <a href="https://guide.fabrixapi.com/en-us/subscription-approval/" class="font-weight-bold" target="_blank">Learn More <i class="fas fa-arrow-right ml-1" /></a>
          </div>
          <div class="col-12 col-md-6">
            <div class="w-80 w-md-100 w-lg-75 mx-auto">
              <img class="img-fluid" src="@/assets/img/products/whats-new-aug2023/subscription-approvals.jpg" alt="Customizable API Approval Settings">
            </div>
          </div>
        </div>

        <div class="row align-items-center space-2">
          <div class="col-12 col-md-6 mb-4 mb-md-0 order-md-2">
            <h3 class="mb-3">Enhanced Control on API Management</h3>
            <p>You can now seamlessly terminate or stop the renewal of subscription contracts for your API users, allowing you to have complete control over the subscription lifecycle and effectively manage user engagements.</p>
            <a href="https://guide.fabrixapi.com/en-us/stop-renewal-for-subscription/" class="font-weight-bold" target="_blank">Learn More <i class="fas fa-arrow-right ml-1" /></a>
          </div>
          <div class="col-12 col-md-6 order-md-1">
            <div class="w-80 w-md-100 w-lg-75 mx-auto">
              <img class="img-fluid" src="@/assets/img/products/whats-new-aug2023/subscription-contract.jpg" alt="Enhanced Control on API Management">
            </div>
          </div>
        </div>

        <div class="row align-items-center space-2">
          <div class="col-12 col-md-6 mb-4 mb-md-0">
            <h3 class="mb-3">Online or Offline Payment—it's up to You</h3>
            <p>To enhance monetization flexibility, we now offer more collection options and empowering you to collect payments through your preferred offline methods, such as offline bank transfers or separate payment links. This enables you to accommodate various payment preferences and provides a seamless experience for your API users.</p>
            <a href="https://guide.fabrixapi.com/en-us/set-up-api-monetization/" class="font-weight-bold" target="_blank">Learn More <i class="fas fa-arrow-right ml-1" /></a>
          </div>
          <div class="col-12 col-md-6">
            <div class="w-80 w-md-100 w-lg-75 mx-auto">
              <img class="img-fluid" src="@/assets/svg/illustrations/management/management-feature-3.svg" alt="Manage API Subscriptions">
            </div>
          </div>
        </div>
      </div>

      <!-- Book a Demo -->
      <div class="container space-top-2 space-bottom-3">
        <div class="card border-0 shadow" :style="{ backgroundImage: `url(${require(`@/assets/svg/components/ben-hero-1.svg`)})`}">
          <div class="card-body text-center py-5">
            <h2>Ready to Build Your Own API Store?</h2>
            <p>Start building for free and share your APIs to the world.</p>
            <a href="https://provider-portal.fabrixapi.com/" class="btn btn-primary mt-3" target="_blank">Start for Free <i class="far fa-arrow-right ml-1" /></a>
          </div>
        </div>
      </div>

    </div>
  </main>
</template>

<script>
import 'bootstrap'
export default {
  name: 'WhatsNewAug2023',
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  async mounted() {
    this.loadScriptUpgradeForm('https://js.hsforms.net/forms/v2.js', () => {
      const secondScript = document.createElement('script')
      secondScript.innerHTML = `
        hbspt.forms.create({
          portalId: "7091000",
          formId: "b20fb3bd-0aea-45b5-9493-c02301f45050"
        });
      `
      this.$refs.upgradeForm.appendChild(secondScript)
    })
  },
  methods: {
    loadScriptUpgradeForm(url, callback) {
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = url
      script.onreadystatechange = callback
      script.onload = callback
      this.$refs.upgradeForm.appendChild(script)
    }
  },
  metaInfo() {
    return {
      title: 'Whats New on FabriXAPI - Aug 2023',
      titleTemplate: '%s | FabriXAPI',
      link: [
        { rel: 'canonical', href: 'https://www.fabrixapi.com/whats-new-aug2023' }
      ],
      meta: [
        { name: 'description', content: 'An award-winning one-stop API platform services connecting digital businesses to embrace Open API Economy.' },
        { property: 'og:site_name', content: 'FabriXAPI' },
        { property: 'og:title', content: 'What\'s New on FabriXAPI - Aug 2023 | FabriXAPI' },
        { property: 'og:description', content: 'An award-winning one-stop API platform services connecting digital businesses to embrace Open API Economy.' },
        { property: 'og:image', content: 'https://www.fabrixapi.com/assets/img/thumbnails/fabrixapi-thumbnail.jpg' },
        { property: 'og:url', content: 'https://www.fabrixapi.com/whats-new-aug2023' }
      ]
    }
  }
}
</script>

<style lang="scss">
</style>
